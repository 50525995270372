import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".chronicle-item__date").forEach(section => {
    (section as HTMLElement).style.transform = "translateY(15%)";
    (section as HTMLElement).style.opacity = "0.25";

    gsap.to((section as HTMLElement), {
        y: 0,
        ease: "none",
        opacity: 1,
        scrollTrigger: {
            trigger: section as HTMLElement,
            end: "bottom center",
            scrub: 1.25
        }
    });
});

gsap.utils.toArray(".chronicle-item__text").forEach(section => {
    (section as HTMLElement).style.transform = "translateY(25%)";
    (section as HTMLElement).style.opacity = "0";

    gsap.to((section as HTMLElement), {
        y: 0,
        ease: "none",
        opacity: 1,
        scrollTrigger: {
            trigger: section as HTMLElement,
            end: "bottom center",
            scrub: 1.5
        }
    });
});

gsap.utils.toArray(".chronicle-item__image img").forEach(section => {
    (section as HTMLElement).style.transform = `translateY(25%)`;
    (section as HTMLElement).style.opacity = "0";

    gsap.to((section as HTMLElement), {
        y: 0,
        ease: "none",
        opacity: 1,
        scrollTrigger: {
            trigger: section as HTMLElement,
            end: "bottom bottom",
            scrub: 2
        }
    });
});