import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".content-grid__row").forEach(section => {
    const sectionImg = (section as HTMLElement).querySelector(".content-grid__row__visual") as HTMLElement;
    const sectionContent = (section as HTMLElement).querySelector(".content-grid__row__text") as HTMLElement;

    sectionImg.style.transform = "translateY(15%)";
    sectionImg.style.opacity = "0.25";
    sectionContent.style.transform = "translateY(25%)";

    gsap.to(sectionImg, {
        y: 0,
        ease: "none",
        opacity: 1,
        scrollTrigger: {
            trigger: section as HTMLElement,
            end: "bottom bottom",
            scrub: 1.75
        }
    });
    gsap.to(sectionContent, {
        y: 0,
        ease: "none",
        scrollTrigger: {
            trigger: section as HTMLElement,
            end: "bottom bottom",
            scrub: 1.75
        }
    });
});