import { Component } from "../../../libs/components";

class FooterComponent extends Component {
    metaNavEl: HTMLElement;

    onInit(): void {
        this.metaNavEl = this.host.querySelector('.footer__infos__links__nav');
        this.positionMetaNav();
        window.addEventListener('resize', this.positionMetaNav.bind(this));
    }

    positionMetaNav() {
        if (window.innerWidth < 992) {
            // Mobile
            const logoEl = this.host.querySelector('#footer-wappen');
            logoEl.parentElement.appendChild(this.metaNavEl);
        } else {
            // Desktop
            const ctaButton = this.host.querySelector('.footer__infos__links__cta');
            ctaButton.parentElement.appendChild(this.metaNavEl);
        }
    }
}
FooterComponent.declare('.footer');