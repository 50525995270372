import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".text-transparent-visual").forEach(section => {
    const sectionImage = (section as HTMLElement).querySelector(".text-transparent-visual__image") as HTMLElement;
    const sectionContent = (section as HTMLElement).querySelector(".text-transparent-visual__content") as HTMLElement;

    (section as HTMLElement).style.overflow = "hidden";
    sectionContent.style.opacity = "0";
    sectionContent.style.transform = "translateY(20%)";

    sectionImage.style.opacity = "0";
    sectionImage.style.transform = "translateY(40%)";


    gsap.to(sectionContent, {
        opacity: 1,
        y: 0,
        ease: "none",
        scrollTrigger: {
            trigger: section as HTMLElement,
            scrub: 1.5,
            end: "-30%",
        }
    });

    gsap.to(sectionImage, {
        opacity: 1,
        y: 0,
        ease: "none",
        scrollTrigger: {
            trigger: section as HTMLElement,
            scrub: 1.5,
            end: "25%",
        }
    });
});