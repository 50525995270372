export interface IdGenerator<T> {
  next(): T;
}

export class DefaultIdGenerator implements IdGenerator<number> {
  private lastId = -1;

  next(): number {
    const newId = this.lastId + 1;
    this.lastId = newId;
    return newId;
  }
}
