import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".full-bleed-text").forEach(section => {
    const sectionContent = (section as HTMLElement).querySelector(".full-bleed-text__inner") as HTMLElement;

    (section as HTMLElement).style.overflow = "hidden";
    sectionContent.style.opacity = "0";
    sectionContent.style.transform = "translateY(20%)";

    const hasIntroAnimation = document.getElementById('intro-animation');
    const isHome = document.location.pathname == '/';
    const isMobile = window.innerWidth <= 768;

    if (isHome) {
        gsap.to(sectionContent, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            delay: hasIntroAnimation ? 1.5 : 0,
            ease: "sine.inOut",
            scrollTrigger: {
                start: "top bottom",
                end: "bottom bottom",
                trigger: section as HTMLElement,
                scrub: isMobile ? false : 1.25,
            }
        });
    } else {
        gsap.to(sectionContent, {
            opacity: 1,
            y: 0,
            duration: 0.5,
            ease: "sine.inOut",
            scrollTrigger: {
                trigger: section as HTMLElement,
                scrub: 1.5,
                end: "-30%",
            }
        });
    }
});