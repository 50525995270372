import { Component } from "libs/components";

class MunicipalityFilterComponent extends Component {
    onInit(): void {
        this.host.querySelector('.municipality-filters__toggler').addEventListener('click', event => {
            event.preventDefault();
            this.toggleFilters();
        });
    }

    toggleFilters() {
        const filterBtn = this.host.querySelector('.municipality-filters__toggler');
        const filters = this.host.querySelector('.accordion--map');
        const accordionVisibleClass = 'accordion--visible';

        if (filters.classList.contains(accordionVisibleClass)) {
            // Close it
            filters.classList.remove(accordionVisibleClass);
            filterBtn.textContent = filterBtn.getAttribute('data-default-text');
        } else {
            // Open it
            filters.classList.add(accordionVisibleClass);
            filterBtn.textContent = filterBtn.getAttribute('data-alternative-text');
        }
    }
}
MunicipalityFilterComponent.declare('.municipality-filters');