import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";


gsap.registerPlugin(ScrollTrigger);

gsap.utils.toArray(".full-bleed-visual--parallax").forEach(section => {
    const sectionBackground = (section as HTMLElement).querySelector(".full-bleed-visual__image") as HTMLElement;

    if (sectionBackground.tagName.toLowerCase() == 'picture') {
        const backgroundImage = (sectionBackground.querySelector('picture img') as HTMLImageElement);

        backgroundImage.onload = () => {
            sectionBackground.style.backgroundImage = `url(${backgroundImage.currentSrc})`;
        }
    }

    sectionBackground.style.backgroundImage = `url(${sectionBackground.getAttribute('data-src')})`;
    sectionBackground.style.backgroundPosition = `50% ${innerHeight / 2}px`;

    gsap.to(sectionBackground, {
        backgroundPosition: `50% ${-innerHeight / 2}px`,
        ease: "none",
        scrollTrigger: {
            trigger: section as HTMLElement,
            scrub: true
        }
    });
});