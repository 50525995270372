import { Component } from "../../../libs/components";


class AccordionComponent extends Component {
    onInit(): void {
        this.host.querySelector('.accordion__item__title__link').addEventListener('click', event => {
            event.preventDefault();
            this.toggleItem((event.currentTarget as HTMLElement));
        });
    }

    toggleItem(togglerEl: HTMLElement) {
        const direction = togglerEl.getAttribute('aria-expanded') == 'true' ? 'close' : 'open';
        const contentEl = this.host.querySelector(`${togglerEl.getAttribute('href')}`);

        if (direction == 'open') {
            togglerEl.setAttribute('aria-expanded', 'true');
            contentEl.setAttribute('aria-hidden', 'false');
        }

        if (direction == 'close') {
            togglerEl.setAttribute('aria-expanded', 'false');
            contentEl.setAttribute('aria-hidden', 'true');
        }
    }
}
AccordionComponent.declare('.accordion__item');