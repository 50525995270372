import { Component } from "libs/components";

class MunicipalityDetailsCloserComponent extends Component {
    onInit(): void {
        this.host.addEventListener('click', event => {
            event.preventDefault();
            this.closeOverlay();
            this.unsetFilters();
        });
    }

    closeOverlay() {
        // ... and remove its content.
        const parentEl = this.host.closest('.municipality-details');
        history.pushState({}, "", this.host.getAttribute('data-href'));

        setTimeout(() => {
            parentEl.querySelector('.municipality-details__content').innerHTML = "";
        }, 300);

        unsetActiveMapItem();
        parentEl.classList.add('municipality-details--hidden');
    }

    unsetFilters() {
        // remove active class from location link.
        document.querySelectorAll('.municipality-filters .accordion__item__link--active').forEach(el => {
            el.classList.remove('accordion__item__link--active');
        });
    }
}
MunicipalityDetailsCloserComponent.declare('.municipality-details__closer');

export function loadMapDetail(detailURL: string) {
    const parentEl = document.querySelector('.municipality-details');
    const xhr = new XMLHttpRequest();

    document.querySelectorAll('.municipality-filters .accordion__item__link--active').forEach(el => {
        el.classList.remove('accordion__item__link--active');
    });
    document.querySelector(`.municipality-filters .accordion__item__link[href="${detailURL}"]`).classList.add('accordion__item__link--active');

    history.pushState({}, "", detailURL);

    xhr.open('GET', detailURL);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.onload = function () {
        if (this.status == 200) {
            parentEl.classList.remove('municipality-details--hidden');
            parentEl.querySelector('.municipality-details__content').innerHTML = this.response;
        }
    };
    xhr.send();
}

export function unsetActiveMapItem() {
    if (window.municipalityMap) {
        window.municipalityMap.data.forEach(feature => {
            feature.setProperty("isActive", false);
        })
    }
}