const introAnimationEl = document.getElementById('intro-animation');

if (introAnimationEl) {
    setTimeout(() => {
        introAnimationEl.classList.remove('reveal');
    }, 300);

    setTimeout(() => {
        introAnimationEl.classList.add('hide');
    }, 1150);

    setTimeout(() => {
        introAnimationEl.remove()
    }, 2000);
}