import { Component } from "libs/components";
import { Loader } from "@googlemaps/js-api-loader"
import { BGAMapStyles } from "./map-styles";
import { loadMapDetail } from "./municipality-details";

class MunicipalityMapComponent extends Component {
    apiKey = "AIzaSyCF2vWibbl8EJ8LO-yRZyv65UC7RcydcUw";
    map;
    highlightColor = '#148A64';

    onInit(): void {
        this.loadMap(this.initMap.bind(this));
    }

    async initMap() {
        const { Map } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
        this.map = new Map(this.host, {
            center: { lat: 47.5149257, lng: 9.4304026 },
            zoom: 14,
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            styles: BGAMapStyles
        });
        window.municipalityMap = this.map;
        this.host.classList.add('map-loaded');
        this.loadMapPolygons();
    }

    loadMapPolygons() {
        const scriptDataTag = document.getElementById('municipality-map-data');
        if (!scriptDataTag) return;

        this.map.data.addListener('addfeature', event => {
            const featurePath = event.feature.getProperty('detail-url');
            const currentPath = window.location.pathname;

            if (featurePath == currentPath) {
                event.feature.setProperty("isActive", true);
            }
        })


        const mapData = JSON.parse(scriptDataTag.textContent);
        mapData.forEach(location => {
            this.map.data.addGeoJson(location.geojson_polygon, {
                idPropertyName: location.slug
            });
        });


        this.map.data.setStyle((feature) => {
            let color = "#E63329";
            const strokeWeight = 1;

            if (feature.getProperty("isActive")) {
                color = this.highlightColor;
            }
            return /** @type {!google.maps.Data.StyleOptions} */ {
                fillColor: color,
                strokeColor: color,
                strokeWeight: strokeWeight,
            };
        });

        this.map.data.addListener('click', event => {
            const detailURL = event.feature.getProperty('detail-url');
            this.map.data.forEach(feature => {
                feature.setProperty("isActive", false);
            });
            this.map.data.revertStyle();
            event.feature.setProperty("isActive", true);

            loadMapDetail(detailURL);

            const bounds = new google.maps.LatLngBounds();
            event.feature.getGeometry().forEachLatLng(callback => {
                bounds.extend(callback)
            });

            this.map.data.overrideStyle(event.feature, { fillColor: this.highlightColor });
            this.map.setCenter(bounds.getCenter());
            this.map.setZoom(16);
        });

        this.map.data.addListener("mouseover", (event) => {
            if (!event.feature.getProperty('isActive')) {
                this.map.data.overrideStyle(event.feature, { strokeWeight: 2 });
            }
        });

        this.map.data.addListener("mouseout", (event) => {
            if (!event.feature.getProperty('isActive')) {
                this.map.data.revertStyle(event.feature);
            }
        });
    }

    loadMap(callback): void {
        // https://googlemaps.github.io/js-api-loader/classes/Loader.html#version
        const loader = new Loader({
            apiKey: this.apiKey,
            version: "quarterly",
            language: "de",
            region: "CH"
        });

        loader.load().then(async () => {
            callback();
        });
    }
}

MunicipalityMapComponent.declare('#municipality-map');
