import { Component } from "libs/components";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


class MosaicItemComponent extends Component {
    onInit(): void {
        if (!this.host.classList.contains('mosaic__item--main')) {
            this.host.querySelectorAll('.mosaic__item__topline, .mosaic__item__headline').forEach(el => {
                el.addEventListener('click', this.toggle.bind(this));
            });
        }
    }

    toggle(event: Event) {
        const target = event.target as HTMLElement;
        const mosaicItem = target.closest('.mosaic__item');

        if (mosaicItem.classList.contains('mosaic__item--open')) {
            // Close item
            mosaicItem.classList.remove('mosaic__item--open')
        } else {
            // Open item
            mosaicItem.classList.add('mosaic__item--open')
        }
    }

}
MosaicItemComponent.declare('.mosaic__item');



class MosaicContainerComponent extends Component {
    textElements;
    visualElements;
    firstRevealDone = false;

    onInit(): void {
        this.textElements = this.host.querySelectorAll('.mosaic__item__content');
        this.visualElements = this.host.querySelectorAll('.mosaic__item__image');
        this.registerRevealAnimation();

        this.visualElements.forEach(element => {
            element.classList.add('reveal');
        })
    }

    registerRevealAnimation() {
        ScrollTrigger.create({
            trigger: this.host,
            end: "bottom bottom",
            onUpdate: self => {
                this.updateElementsOnScroll(self.progress, self.direction);
            }
        });
    }

    updateElementsOnScroll(scrollProgress: number, direction: number) {
        this.textElements.forEach(element => {
            element.style.opacity = scrollProgress;
        });

        if (direction == -1 || this.firstRevealDone) {
            this.visualElements.forEach(element => {
                element.style.opacity = scrollProgress;
            });
            this.firstRevealDone = true;
        }

        if (scrollProgress > 0.55) {
            let timeoutDuration = 0;
            this.visualElements.forEach(element => {
                setTimeout(() => {
                    element.classList.remove('reveal');
                }, timeoutDuration);
                timeoutDuration += 250;
            });
        }
    }
}
MosaicContainerComponent.declare('section.mosaic');