import { Component } from "../../../libs/components";

class HeaderComponent extends Component {
    onInit(): void {
        this.host.querySelector('.header__nav-toggler').addEventListener('click', event => {
            event.preventDefault();
            this.host.classList.toggle('header--nav-open');
        })
    }
}
HeaderComponent.declare('.header');